import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import { Hide } from "@rebass/hide";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Icon from "react-icons-kit";
import { cameraRetro } from "react-icons-kit/fa/cameraRetro";
import { comments } from "react-icons-kit/fa/comments";
import { folderOpen } from "react-icons-kit/fa/folderOpen";
import { gavel } from "react-icons-kit/fa/gavel";
import { heart } from "react-icons-kit/fa/heart";
import { magic } from "react-icons-kit/fa/magic";
import { phone } from "react-icons-kit/fa/phone";
import { search } from "react-icons-kit/fa/search";
import { trophy } from "react-icons-kit/fa/trophy";
import { users } from "react-icons-kit/fa/users";
import { LinkButton } from "../../components/Button";
import Card from "../../components/Card";
import ContactCard from "../../components/ContactCard";
import GoogleReviews from "../../components/GoogleReviews";
import Hero from "../../components/Hero";
import Page from "../../components/Page";
import PageBlock from "../../components/PageBlock";
import PageTitle from "../../components/PageTitle";
import ProfileImage from "../../components/ProfileImage";
import RoundedImage from "../../components/RoundedImage";
import Service from "../../components/Service";
import References from "../../components/References";
import SectionTitle from "../../components/SectionTitle";
import Testimonials from "../../components/Testimonials";
import { Link } from "gatsby";
import ImmoweltBadge from "../../components/ImmoweltBadge";
import SectionTeam from "../../components/SectionTeam";

const WideBackground = styled(Flex)`
  background: radial-gradient(
    90% 150% at 50% 120%,
    rgba(189, 18, 30),
    rgba(105, 31, 131)
  );
  color: white;
  margin-bottom: 100px;
  margin-top: 100px;
`;

const BigIcon = styled(Box)`
  text-align: center;
  color: #961452;
  margin-bottom: 32px;
`;

const ServiceCard = styled(Card)`
  margin-bottom: 32px;
`;

const ImmobilienmaklerSaalfeld = ({ data }) => (
  <Page
    address={{
      streetAddress: "Breitscheidstraße 92",
      postalCode: "07407",
      city: "Rudolstadt",
      phone: "0174 3217733",
    }}
    header={
      <Hero fluid={data.homeHeroImage.fluid}>
        <Flex
          alignItems="center"
          flexWrap="wrap"
          css={css`
            max-width: 50em;
          `}
        >
          <Box
            width={[1, null, 2 / 3]}
            css={css`
              text-align: left;
            `}
          >
            <PageTitle>Immobilienmakler Saalfeld</PageTitle>
            <p>
            Sie haben eine Vielzahl von Aufgaben vor sich, da Sie Ihre Immobilie in Saalfeld verkaufen möchten? Oder sind Sie auf der Suche nach einer 
            eigenen Immobilie? Wir verstehen, wie herausfordernd das sein kann und stehen Ihnen nicht allein gegenüber. Als spezialisierter Immobilienmakler 
            für Saalfeld sind wir gerne für Sie da und helfen Ihnen bei jedem Schritt.
            </p>
            <Flex alignItems="stretch" flexWrap="wrap">
              <Hide xsmall>
                <ImmoweltBadge />
              </Hide>
              <GoogleReviews />
            </Flex>
          </Box>
          <Hide width={[1, null, 1 / 3]} paddingLeft={5} xsmall small>
            <ProfileImage version={2} />
            <p>
              <strong>Linda Bäumer</strong>
              <br />
              Immobilienmaklerin für Saalfeld
            </p>
          </Hide>
        </Flex>
      </Hero>
    }
  >
    <Helmet
      title="Immobilienmakler für Saalfeld"
      meta={[
        {
          name: "description",
          content:
            "Erfolg haben Sie nur mit den besten Immobilienmaklern in Saalfeld. Jetzt kostenlos beraten lassen.",
        },
      ]}
    />

    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} my={4} p={3}>
          <Card collapsed>
            <Flex alignItems="center" flexWrap="wrap" mx={-3}>
              <Box width={[1, 1, 1 / 3]} px={[3, 3, 3]} mb={[3, 3, 0]}>
                <RoundedImage
                  fluid={data.founderImage.fluid}
                  alt="Linda Bäumer"
                />
              </Box>
              <Box width={[1, null, 2 / 3]} px={3}>
                <h2>Immobilienmakler Saalfeld</h2>
                <p>
                  Um auf dem Immobilienmarkt erfolgreich zu sein, ist es wichtig, einen starken Partner an seiner 
                  Seite zu haben. Linda Bäumer ist genau dieser starke Partner. Als erfahrene <Link to="/"> Immobilienmaklerin aus Rudolstadt</Link>, steht 
                  sie Ihnen auch in Saalfeld kompetent zur Seite und unterstützt Sie in allen Belangen. Verlassen Sie sich auf ihre Expertise 
                  und profitieren Sie von ihrer langjährigen Erfahrung.
                </p>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Flex>

      {/* Not needed for the moment */}

      {/*
      <Flex justifyContent="center">
        <Box width={[1, null, "30em"]} my={2} p={3}>
          <p style={{ textAlign: "center" }}>
            Was mich in Erfurt so erfolgreich macht? Zielorientiertes Arbeiten
            mit Immobilien aller Art (Haus, Wohnung & Grundstück) gepaart mit
            meinen zwischenmenschlichen Fähigkeiten.{" "}
            <strong>Der Mensch steht bei mir stets an erster Stelle.</strong>
          </p>
        </Box>
      </Flex>
      */}

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} my={4} p={3}>
          <Flex justifyContent="center" flexWrap="wrap" mx={-3}>
            <Box width={[1, null, 1 / 3]} px={3}>
              <BigIcon>
                <Icon size={50} icon={trophy} />
              </BigIcon>
              <h4>Kompetenz & Erfahrung</h4>
              <p>
              Mit über 8 Jahren Erfahrung als erfolgreiche Immobilienmaklerin in Saalfeld ist Linda Bäumer Ihre kompetente Ansprechpartnerin.
              </p>
            </Box>
            <Box width={[1, null, 1 / 3]} px={3}>
              <BigIcon>
                <Icon size={50} icon={comments} />
              </BigIcon>
              <h4>Vertrauen durch Transparenz</h4>
              <p>
                Bei der Auswahl eines Maklers für den Verkauf des eigenen Hauses oder der Eigentumswohnung ist Vertrauen entscheidend. Wir legen großen Wert auf Ehrlichkeit und Transparenz, um dieses Vertrauen aufzubauen.
              </p>
            </Box>
            <Box width={[1, null, 1 / 3]} px={3}>
              <BigIcon>
                <Icon size={50} icon={heart} />
              </BigIcon>
              <h4>Mehr als nur Häuser</h4>
              <p>
                Bei uns dreht sich nicht nur alles um Ihre Wohnung oder Ihr Haus, sondern auch um Sie als Person. Ihr Wohlbefinden liegt uns am Herzen, und wir berücksichtigen Ihre individuellen Wünsche und Bedürfnisse.
              </p>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} my={4} p={3}>
          <Card collapsed>
            <Flex alignItems="center" flexWrap="wrap" mx={-3}>
              <Box width={[1, 1, 1 / 3]} px={[3, 3, 3]} mb={[3, 3, 0]}>
                <RoundedImage fluid={data.localImage.fluid} alt="Saalfeld" />
              </Box>
              <Box width={[1, null, 2 / 3]} px={3}>
                <h2>Saalfeld - einfach charmant</h2>
                <p>
                  Saalfeld, eine charmante Stadt in Thüringen, beeindruckt mit ihrer reichen Geschichte und malerischen Umgebung.
                  Mit einer Vielzahl von historischen Gebäuden, verwinkelten Gassen und grünen Parks bietet Saalfeld eine einzigartige 
                  Atmosphäre. Die Stadt ist bekannt für ihr imposantes Schloss, das majestätisch über der Altstadt thront, sowie für ihre 
                  vielfältigen kulturellen Veranstaltungen und traditionellen Festivals. Saalfeld lockt zudem mit einer guten Infrastruktur, 
                  ausgezeichneten Bildungseinrichtungen und einer lebendigen Gemeinschaft. 
                </p>
                <p>  
                  Der Immobilienmarkt in Saalfeld ist geprägt von einer 
                  Vielfalt an Angeboten, darunter charmante Altbauwohnungen, moderne Neubauten und attraktive Grundstücke. Mit der Unterstützung 
                  eines erfahrenen Immobilienmaklers können Interessenten in Saalfeld ihr persönliches Traumobjekt finden und Teil dieser 
                  lebenswerten Stadt werden.
                </p>
                <p>
                  Als<Link to="/"> Immobilienmakler in Rudolstadt</Link> sind wir auch in<Link to="/immobilienmakler-bad-blankenburg"> Bad Blankenburg</Link>, sowie im gesamten Landkreis <Link to="/immobilienmakler-saalfeld-rudolstadt">Saalfeld-Rudolstadt</Link> für Sie da. 
                </p>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} my={4} p={3}>
          <SectionTitle center>Immobilienverkauf in Saalfeld</SectionTitle>
          <p style={{ textAlign: "center" }}>
            Der Verkauf von Immobilien in Saalfeld erfordert stets eine professionelle Herangehensweise. 
            Selbst kleine Fehler können Eigentümer viel Geld kosten, und der Arbeitsaufwand sollte nicht 
            unterschätzt werden. Nutzen Sie unser Rundum-Sorglos-Paket und lehnen Sie sich während des 
            Verkaufsprozesses entspannt zurück. Wir kümmern uns um alle Aspekte und sorgen für einen reibungslosen 
            Ablauf, während Sie sich auf andere Dinge konzentrieren können. 
          </p>
          <Flex flexWrap="wrap">
            <Service
              icon={search}
              title="Immobilienbewertung"
              description="Bei der Immobilienbewertung wird nicht geschätzt, sondern der Wert Ihrer Immobilie fach- und sachgerecht ermittelt."
            />
            <Service
              icon={cameraRetro}
              title="Fotografie und Exposés"
              description="Aussagekräftige Werbemittel sind das A und O. Deshalb werden für Sie ausschließlich hochprofessionelle Werbemittel in bester Qualität erstellt."
            />
            <Service
              icon={magic}
              title="Vermarktung"
              description="Ihre Immobilie wird proaktiv vermarktet. Mit einer regionalen und überregionalen Platzierung des Objektes können wir reichweitenstark potenzielle Käufer ansprechen."
            />
            <Service
              icon={folderOpen}
              title="Bürokratie überwinden"
              description="Die Bürokratie hat die Immobilienwirtschaft fest im Griff. Aber keine Sorge: Es werden alle notwendigen Unterlagen für Sie bereitgestellt und aufgearbeitet."
            />
            <Service
              icon={users}
              title="Besichtigung und Käuferprüfung"
              description="Von der Anfrage bis zur Besichtigung und Bonitätsprüfung. Es wird der perfekte Käufer auf Grundlage Ihrer Kriterien gefunden."
            />
            <Service
              icon={gavel}
              title="Verträge und Notar"
              description="Sobald der passende Käufer gefunden wurde, werden die letzten Schritte bis zur notariellen Beglaubigung sauber vorbereitet."
            />
          </Flex>
        </Box>
      </Flex>


    </PageBlock>



    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} p={3}>
          <SectionTitle center>Immobilien in Saalfeld kaufen</SectionTitle>
          <p>
            Es gibt viele wunderschöne Objekte in Saalfeld, die nur auf Sie
            warten. Als Makler für die Stadt Saalfeld helfen wir Ihnen dabei,
            das richtige Objekt zu finden. Schließlich möchten Sie nicht mehr
            lange nur mieten, sondern Ihr eigenes Heim besitzen.
          </p>
          <Box mx={[0, 0, -40]}>
            <ServiceCard red collapsed>
              <h3>Aktuelle Angebote</h3>
              <p>
                Suchen Sie nicht, sondern finden Sie Ihre Immobilie: Schauen Sie
                in die Übersicht unserer aktuellen Objekte. Wir haben spannende
                Immobilien aus Saalfeld und darüber hinaus.
              </p>
              <div>
                <LinkButton to="/immobilien-rudolstadt/">Zu den Angeboten</LinkButton>
              </div>
            </ServiceCard>

            {/*
            <ServiceCard purple collapsed>
              <h3>VIP-Suche</h3>
              <p>
                Die Suche nach einem passenden Haus oder der perfekten
                Eigentumswohnung kann in Erfurt zum Marathon werden. Nutzen Sie
                meinen VIP-Service, damit ich Ihnen bei Ihrer Suche
                vollumfänglich helfen kann.
              </p>
              <div>
                <LinkButton to="/angebote/">
                  Zur VIP Suche und aktuellen Angeboten
                </LinkButton>
              </div>
            </ServiceCard>

            <ServiceCard red collapsed>
              <h3>Exklusives Homestaging</h3>
              <p>
                Weniger als 20% aller Menschen können sich leere Räume möbliert
                vorstellen und deren Dimensionen richtig abschätzen. Als
                Immobilienmakler weiß ich: Für den ersten Eindruck gibt es keine
                zweite Chance. Mit dem Homestaging-Service verhelfe ich Ihrer
                Immobilie zum perfekten ersten Eindruck.
              </p>
              <div>
                <LinkButton to="/homestaging/">Mehr zum Homestaging</LinkButton>
              </div>
            </ServiceCard>
            */}
          </Box>
        </Box>
      </Flex>
    </PageBlock>

    <PageBlock>
        <SectionTeam />
    </PageBlock>

    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "70em"]} my={5} p={3} justifyContent="center">
          <SectionTitle center>Was meine Kunden über mich sagen</SectionTitle>
          <Testimonials data={data.testimonials} />
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <p>Viele weitere Stimmen zu meiner Arbeit finden Sie auf Google:</p>
            <Box width={[1, "20em"]}>
              <GoogleReviews />
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "60em"]} my={5} p={3} justifyContent="center">
          <SectionTitle center>Referenzobjekte</SectionTitle>
          <Box width={[1, null, "40em"]} margin="auto">
            <p style={{ textAlign: "center" }}>
              Erzählen kann man vieles. Doch ich habe viele Erfolge
              nachzuweisen. Ganz gleich ob Einfamilienhaus, Eigentumswohnung
              oder ungewöhnliche Objekte — aber machen Sie sich doch ein eigenes
              Bild:
            </p>
          </Box>
          <References data={data.references} />
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, 1 / 2]} my={5} p={3}>
          <ContactCard />
        </Box>
      </Flex>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    homeHeroImage: imageSharp(
      fluid: { originalName: { regex: "/home-hero/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    founderImage: imageSharp(fluid: { originalName: { regex: "/linda_2/" } }) {
      fluid(maxWidth: 500, maxHeight: 500, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    localImage: imageSharp(fluid: { originalName: { regex: "/saalfeld-local/" } }) {
      fluid(maxWidth: 500, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    references: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/references/" } }
      sort: { fields: [frontmatter___order] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            location
            type
            order
            difficulty
            price
            time
          }
          html
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/testimonials/" } }
      sort: { fields: [fileAbsolutePath] }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 250, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
          }
          html
        }
      }
    }
  }
`;

export default ImmobilienmaklerSaalfeld;
